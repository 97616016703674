.gridInterno .jqx-widget-header{
            background-color: #a5d6a7 !important;
            color:black !important;
        }

.gridInterno .jqx-grid-cell-filter-row {
    background-color: #feeee2 !important; /*a5d6a7*/
    color: black !important;
    font-weight: 900 !important;
}

        .jqx-widget-header {
            background-color: #1565c0 !important;
            color:white !important;
            font-weight:600 !important;
        }

.jqx-grid-statusbar {
    background-color: #1565c0 !important;
    color: white !important;
    font-weight: 600 !important;
}

.jqx-grid-cell-filter-row {
    background-color: #feeee2 !important; /*1565c0*/
    color: black !important;
    font-weight: 900 !important;
}


.jqx-grid-pager {
    background-color: #616161 !important;
    color: white !important;
    font-weight: 600 !important;
}

.gridInterno .jqx-grid-pager {
    background-color: #9e9e9e !important;
    color: black !important;
    font-weight: 600 !important;
}

.buttonGrid {
    width:100%;
    height:35px;
    background-color:transparent;
    border:none;
    cursor: pointer;
}

.buttonGridAuto {
    width: auto;
    height: 35px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.jqx-buttonGrid {
    width:100%;
    height:35px !important;
    background-color:transparent !important;
    border:none;
}
.colorBandera {
    color: #ffc400;
}

.colorBanderaVerde {
    color: #4caf50;
}

.colorBanderaRojo {
    color: #c62828;
}

.colorBanderaBlanco {
    color: #f5f5f5;
}

.colorCirculo{
    color:#ffc400;
}

.jqx-enableselect {
    margin-left: 0px !important;
}

.jqx-grid-cell-selected-metro {
    background-color: white !important;
    color: black !important;
    border-color: #e5e5e5 !important;
}

.jqx-enableselect{
    z-index: 999 !important;
}

.jqx-widget-header-material {
    font-size: 12px !important;
}

.jqx-widget-content-material {
    font-size: 12px !important;
}

.jqx-grid-pager-material .jqx-grid-pager-number-material.jqx-fill-state-pressed-material {
    color: white !important;
}

.jqx-fill-state-hover-material{
    font-size: 12px !important;
}

.calendar {
    min-width: 1rem !important;
}

.jqx-input-label-material {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
}